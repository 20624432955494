import React, { useState } from "react";
import ButtonComponent from "../../../UIComponents/ButtonComponent/ButtonComponent";
import AnswerCardComponent from "./AnswerCardComponent/AnswerCardComponent";
import agencyPNG from "./img/agency.png";
import businessPNG from "./img/business.png";
import "./SignUpQuizComponent.css";
import { ChatbotExperienceLevel, HowManyCustomerBotsDoYouExpectToBuild } from "./WizzardContent/QuestionsComponent";

const SignUpQuizComponent = ({ goToNextStep = () => {}, customerID }) => {
  const [selectedSequences, setSelectedSequences] = useState(null);
  const [currentSequences, setCurrentSequences] = useState("business");
  const [currentSequencesQiuzStep, setCurrentSequencesQiuzStep] = useState(0);
  const [businessSequencesSelectedValues,setBusinessSequencesSelectedValues] = useState({
    botsQuantity: "",
    industry: "",
    industry_other: "",
    exp: ""
  });

  // Data structure describing the order of the screens when a sequence is selected
  // Example: { company_type: [ <QuizFirstStep/> ] }
  // Where company_type = sequence name.
  // Array of components for rendering in a certain sequence

  const quizSequencesComponents = {
    company_type: [
      <QuizFirstStep
        setCurrentSequences={setCurrentSequences}
        selectedSequences={selectedSequences}
        setSelectedSequences={setSelectedSequences}
        currentSequences={currentSequences}
        businessSequencesSelectedValues={businessSequencesSelectedValues}
        setBusinessSequencesSelectedValues={setBusinessSequencesSelectedValues}
        customerID={customerID}
      />
    ],
    business: [
      // <WhichIndustry
      //   setCurrentSequences={setCurrentSequences}
      //   selectedSequences={selectedSequences}
      //   setSelectedSequences={setSelectedSequences}
      //   currentSequences={currentSequences}
      //   businessSequencesSelectedValues={businessSequencesSelectedValues}
      //   setBusinessSequencesSelectedValues={setBusinessSequencesSelectedValues}
      //   setCurrentSequencesQiuzStep={setCurrentSequencesQiuzStep}
      //   customerID={customerID}
      // />,
      <ChatbotExperienceLevel
        setCurrentSequences={setCurrentSequences}
        selectedSequences={selectedSequences}
        setSelectedSequences={setSelectedSequences}
        currentSequences={currentSequences}
        businessSequencesSelectedValues={businessSequencesSelectedValues}
        setBusinessSequencesSelectedValues={setBusinessSequencesSelectedValues}
        setCurrentSequencesQiuzStep={setCurrentSequencesQiuzStep}
        goToNextStep={goToNextStep}
        customerID={customerID}
      />
    ],
    agency: [
      <HowManyCustomerBotsDoYouExpectToBuild
        setCurrentSequences={setCurrentSequences}
        selectedSequences={selectedSequences}
        setSelectedSequences={setSelectedSequences}
        currentSequences={currentSequences}
        businessSequencesSelectedValues={businessSequencesSelectedValues}
        setBusinessSequencesSelectedValues={setBusinessSequencesSelectedValues}
        setCurrentSequencesQiuzStep={setCurrentSequencesQiuzStep}
        customerID={customerID}
      />,
      <ChatbotExperienceLevel
        setCurrentSequences={setCurrentSequences}
        selectedSequences={selectedSequences}
        setSelectedSequences={setSelectedSequences}
        currentSequences={currentSequences}
        businessSequencesSelectedValues={businessSequencesSelectedValues}
        setBusinessSequencesSelectedValues={setBusinessSequencesSelectedValues}
        setCurrentSequencesQiuzStep={setCurrentSequencesQiuzStep}
        goToNextStep={goToNextStep}
        customerID={customerID}
      />
    ]
  };

  // A function that returns a component for rendering by clicking on the next/back button
  const currentSequencesQiuzStepSelector = (sequencesQiuz, currentQiuzStep) => {
    switch (currentQiuzStep) {
      default:
        return sequencesQiuz[currentSequences][currentQiuzStep];
    }
  };
  return (
    <div>
      {currentSequencesQiuzStepSelector(
        quizSequencesComponents,
        currentSequencesQiuzStep
      )}
    </div>
  );
};
// The first page of the second step in the wizard. Sequence name - company_type
const QuizFirstStep = ({
  selectedSequences,
  setSelectedSequences,
  currentSequences,
  setCurrentSequences,
  businessSequencesSelectedValues,
  setBusinessSequencesSelectedValues,
  customerID
}) => {
  const sendAnalytics = (cb = () => {}) => {
    if (customerID) {
      console.log("sendAnalytics QuizFirstStep", customerID, selectedSequences);
      window.analytics.identify(customerID, {
        Account_type: businessSequencesSelectedValues.Account_type
      });
      window.analytics.track("Wizard Step Completed", {
        Platform: "Architect",
        Wizard: "Jan 2020 steps:",
        Step: "Business or Agency ",
        "Step result": "Completed"
      });
    }

    cb();
  };
  return (
    <div className='signup_quiz_component'>
      <div className='signup_quiz_sequence_selector_description_wrapper'>
        <span className='signup_quiz_sequence_selector_description'>
          Activechat experience can be quite different for various types of
          users.
        </span>
        <span className='signup_quiz_sequence_selector_description'>
          To help us customize your account, please make two simple choices.
        </span>
      </div>
      <div className='signup_quiz_sequence_selector_question_wrapper'>
        <span className='signup_quiz_sequence_selector_question'>
          Which one describes you best? *
        </span>
      </div>
      <div className='signup_quiz_sequence_selector_answers_wrapper'>
        <AnswerCardComponent
          title={"BUSINESS"}
          selected={selectedSequences}
          description={"I will be using Activechat for my own business"}
          imgSrc={businessPNG}
          answerValue={"business"}
          onClick={() => {
            setBusinessSequencesSelectedValues({
              ...businessSequencesSelectedValues,
              Account_type: "Business"
            });
            setSelectedSequences("business");
          }}
        />
        <AnswerCardComponent
          title={"AGENCY"}
          selected={selectedSequences}
          description={
            "I am an agency and we'll be building bots for customers"
          }
          imgSrc={agencyPNG}
          answerValue={"agency"}
          onClick={() => {
            setBusinessSequencesSelectedValues({
              ...businessSequencesSelectedValues,
              Account_type: "Agency"
            });
            setSelectedSequences("agency");
          }}
        />
      </div>
      <div className={"signup_quiz_sequence_selector_notification_wrapper"}>
        <span lassName={"signup_quiz_sequence_selector_notification"}>
          * You can change your choice later
        </span>
      </div>
      <div
        className={`${
          currentSequences === "company_type"
            ? "signup_quiz_single_button_wrapper"
            : "signup_quiz_mult_buttons_wrapper"
        }`}
      >
        {currentSequences !== "company_type" && (
          <ButtonComponent className={"signup_quiz_button"} acuikit outline>
            Back
          </ButtonComponent>
        )}
        <ButtonComponent
          className={"signup_quiz_button"}
          acuikit
          locked={selectedSequences === null}
          onClick={() => {
            sendAnalytics(setCurrentSequences(selectedSequences));
          }}
        >
          Next
        </ButtonComponent>
      </div>
    </div>
  );
};
export default SignUpQuizComponent;

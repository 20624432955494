const pagesNames = {
  dashboard: "Dashboard",
  users: "People",
  broadcasts: "Broadcasts",
  welcome: "Welcome message",
  keywords: "Keywords",
  "web-messages": "Website messages",
  skills: "Skills",
  nlp: "NLP",
  growtools: "Grow tools",
  landing: "Landing pages",
  qrcodes: "Messenger link and code",
  general: "General",
  channels: "Channels",
  integrations: "Integrations",
  billing: "Billing",
};
export const getPageVisitName = (locationPath: string): string => {
  const splitted = locationPath.split("/");
  const pageVisited = locationPath.split("/")[
    locationPath.split("/").length - 1
  ]
    ? locationPath.split("/")[splitted.length - 1]
    : locationPath.split("/")[locationPath.split("/").length - 2];
  return pagesNames[pageVisited];
};

import React, { } from 'react';
import anim from './img/anim.gif';
import logo from './img/logo.svg';
import './Loading.css';

const LoadingComponent = ({ className = '' }) => (
    <div className="LoadingComponent_wrapper">
        <div className={`loading_logo ${className}`}>
            <img src={logo} alt="" />
        </div>
    </div>
);

export default LoadingComponent;

import React from "react";
import ButtonComponent from "../../../../UIComponents/ButtonComponent/ButtonComponent";
import AnswerCardComponent from "../AnswerCardComponent/AnswerCardComponent";
import onePNG from "./img/onePNG.png";
import onewhitePNG from "./img/onewhitePNG.png";
import roleAgent from './img/roles-agent.png';
import roleDeveloper from './img/roles-developer.png';
import roleExecutive from './img/roles-executive.png';
import roleOther from './img/roles-other.png';
import roleProduct from './img/roles-product.png';
import roleTeamLeader from './img/roles-teamLeader.png';
import threePNG from "./img/threePNG.png";
import threewhitePNG from "./img/threewhitePNG.png";
import twoPNG from "./img/twoPNG.png";
import twowhitePNG from "./img/twowhitePNG.png";
import "./QuestionsComponent.css";

// The second page of the second step in the wizard. Sequence name - agency
export const HowManyCustomerBotsDoYouExpectToBuild = ({
  selectedSequences,
  setSelectedSequences,
  currentSequences,
  setCurrentSequences,
  businessSequencesSelectedValues,
  customerID,
  setCurrentSequencesQiuzStep = () => {},
  setBusinessSequencesSelectedValues = () => {}
}) => {
  const sendAnalytics = (cb = () => {}) => {
    console.log("sendAnalytics QuizFirstStep", businessSequencesSelectedValues);
    // if (customerID) {
    //   console.log("sendAnalytics QuizFirstStep", customerID, selectedSequences);
    //   window.analytics.identify(customerID, {
    //     // Agency_Size: businessSequencesSelectedValues.botsQuantity
    //     Role: 
    //   });
    // }
    // window.analytics.track("Wizard Step Completed", {
    //   Platform: "Architect",
    //   Wizard: "Jan 2020 steps:",
    //   Step: "Agency - No of customers",
    //   "Step result": "Completed"
    // });
    // cb();
  };

  return (
    <div className='signup_quiz_component'>
      <div className='signup_quiz_sequence_selector_description_wrapper'>
        <span className='signup_quiz_sequence_selector_description'>
          Activechat experience can be quite different for various types of
          users.
        </span>
        <span className='signup_quiz_sequence_selector_description'>
          To help us customize your account, please make two simple choices.
        </span>
      </div>
      <div className='signup_quiz_sequence_selector_question_wrapper'>
        <span className='signup_quiz_sequence_selector_question'>
          How many customer bots do you expect to build?
        </span>
      </div>
      <div className='signup_quiz_sequence_selector_answers_wrapper'>
        <AnswerCardComponent
          cardType={"small"}
          selected={businessSequencesSelectedValues.botsQuantity}
          description={"1 - 10"}
          imgSrc={
            businessSequencesSelectedValues.botsQuantity === "1-10"
              ? onewhitePNG
              : onePNG
          }
          answerValue={"1-10"}
          onClick={() => {
            setBusinessSequencesSelectedValues({
              ...businessSequencesSelectedValues,
              botsQuantity: "1-10"
            });
          }}
        />
        <AnswerCardComponent
          cardType={"small"}
          selected={businessSequencesSelectedValues.botsQuantity}
          description={"11 - 50"}
          imgSrc={
            businessSequencesSelectedValues.botsQuantity === "11-50"
              ? twowhitePNG
              : twoPNG
          }
          answerValue={"11-50"}
          onClick={() => {
            setBusinessSequencesSelectedValues({
              ...businessSequencesSelectedValues,
              botsQuantity: "11-50"
            });
          }}
        />
        <AnswerCardComponent
          cardType={"small"}
          selected={businessSequencesSelectedValues.botsQuantity}
          description={"more than 50"}
          imgSrc={
            businessSequencesSelectedValues.botsQuantity === "Over 50"
              ? threewhitePNG
              : threePNG
          }
          answerValue={"Over 50"}
          onClick={() => {
            setBusinessSequencesSelectedValues({
              ...businessSequencesSelectedValues,
              botsQuantity: "Over 50"
            });
          }}
        />
      </div>
      <div
        className={
          "signup_quiz_mult_buttons_wrapper signup_quiz_business_mult_buttons_wrapper"
        }
      >
        {/* {currentSequences !== "company_type" && (
          <ButtonComponent
            className={"signup_quiz_button"}
            acuikit
            outline
            onClick={() => {
              setCurrentSequences("company_type");
            }}
          >
            Back
          </ButtonComponent>
        )} */}
        <ButtonComponent
          className={"signup_quiz_button"}
          acuikit
          locked={!businessSequencesSelectedValues.botsQuantity}
          onClick={() => {
            sendAnalytics(setCurrentSequencesQiuzStep(1));
          }}
        >
          Next
        </ButtonComponent>
      </div>
    </div>
  );
};

// The first page of the second step in the wizard. Sequence name - business
// export const WhichIndustry = ({
//   selectedSequences,
//   setSelectedSequences,
//   currentSequences,
//   setCurrentSequences,
//   businessSequencesSelectedValues,
//   customerID,
//   setCurrentSequencesQiuzStep = () => {},
//   setBusinessSequencesSelectedValues = () => {}
// }) => {
//   const sendAnalytics = (cb = () => {}) => {
//     if (customerID) {
//       let data =
//         businessSequencesSelectedValues.industry === "Other (please tell us)"
//           ? {
//               Business_industry: "Other",
//               Business_industry_other:
//                 businessSequencesSelectedValues.industry_other
//             }
//           : {
//               Business_industry: businessSequencesSelectedValues.industry,
//               Business_industry_other: ""
//             };
//       console.log(
//         "sendAnalytics QuizFirstStep",
//         customerID,
//         selectedSequences,
//         data
//       );
//       window.analytics.identify(customerID, data);
//       window.analytics.track("Wizard Step Completed", {
//         Platform: "Architect",
//         Wizard: "Jan 2020 steps:",
//         Step: "Business - Industry",
//         "Step result": "Completed"
//       });
//     }
//   };
//   const selectValues = [
//     "Select from the list",
//     "E-commerce and retail",
//     "Finance and banking",
//     "Insurance company",
//     "Consulting and business services",
//     "Technology and telecom",
//     "Internet and software",
//     "Service company",
//     "Real estate",
//     "Healthcare and beauty",
//     "Sports and recreation",
//     "Restaurants and bars",
//     "Travel and leisure",
//     "Media and news",
//     "Non-profit organization",
//     "Other (please tell us)"
//   ];
//   const InputHandler = (value, key) => {
//     setBusinessSequencesSelectedValues({
//       ...businessSequencesSelectedValues,
//       [key]: value
//     });
//   };
//   return (
//     <div className='signup_quiz_component'>
//       <div className='signup_quiz_sequence_selector_description_wrapper'>
//         <span className='signup_quiz_sequence_selector_description'>
//           Activechat experience can be quite different for various types of
//           users.
//         </span>
//         <span className='signup_quiz_sequence_selector_description'>
//           To help us customize your account, please make two simple choices.
//         </span>
//       </div>
//       <div className='signup_quiz_sequence_selector_question_wrapper'>
//         <span className='signup_quiz_sequence_selector_question'>
//           Which industry are you in?
//         </span>
//       </div>
//       <div className='signup_quiz_sequence_selector_answers_wrapper signup_quiz_sequence_industry_selector_answers_wrapper'>
//         <Select
//           acuikit
//           className='which_industry_select'
//           value={businessSequencesSelectedValues.industry}
//           onChange={value => InputHandler(value, "industry")}
//           options={selectValues.map((item, i) => {
//             if (item === "Select from the list")
//               return {
//                 content: <div className='group_bot_select_option'>{item}</div>,
//                 value: "",
//                 name: item,
//                 hidden: true
//               };
//             else
//               return {
//                 content: <div className='group_bot_select_option'>{item}</div>,
//                 value: item,
//                 name: item
//               };
//           })}
//         />
//         {businessSequencesSelectedValues.industry ===
//           "Other (please tell us)" && (
//           <input
//             type='text'
//             placeholder='Describe your industry'
//             className='which_industry_input'
//             value={businessSequencesSelectedValues.industry_other}
//             onChange={e => {
//               InputHandler(e.target.value, "industry_other");
//             }}
//           />
//         )}
//       </div>
//       <div
//         className={
//           "signup_quiz_mult_buttons_wrapper signup_quiz_business_mult_buttons_wrapper"
//         }
//       >
//         {currentSequences !== "company_type" && (
//           <ButtonComponent
//             className={"signup_quiz_button"}
//             acuikit
//             outline
//             onClick={() => {
//               setCurrentSequences("company_type");
//             }}
//           >
//             Back
//           </ButtonComponent>
//         )}
//         <ButtonComponent
//           className={"signup_quiz_button"}
//           acuikit
//           locked={!businessSequencesSelectedValues.industry}
//           onClick={() => {
//             sendAnalytics(setCurrentSequencesQiuzStep(1));
//           }}
//         >
//           Next
//         </ButtonComponent>
//       </div>
//     </div>
//   );
// };
// // The second page of the second step in the wizard. Sequence name - business, agency
export const ChatbotExperienceLevel = ({
  selectedSequences,
  setSelectedSequences,
  currentSequences,
  setCurrentSequences,
  businessSequencesSelectedValues,
  customerID,
  setCurrentSequencesQiuzStep = () => {},
  setBusinessSequencesSelectedValues = () => {},
  goToNextStep = () => {}
}) => {
  const sendAnalytics = (cb = () => {}) => {
    if (customerID) {
      console.log("sendAnalytics QuizFirstStep", customerID, businessSequencesSelectedValues.exp);
      window.analytics.identify(customerID, {
        Role: businessSequencesSelectedValues.exp
      });
    }
    window.analytics.track("Wizard Step Completed", {
      Platform: "Architect",
      Wizard: "Jan 2020 steps:",
      Step: "Chatbot Experience",
      "Step result": "Completed"
    });
    goToNextStep();
  };

  return (
    <div className='signup_quiz_component signup_quiz_component_business_sequence_step_2'>
      {/* <div className='signup_quiz_sequence_selector_description_wrapper'>
        <span className='signup_quiz_sequence_selector_description'>
          Just one last question!
        </span>
      </div> */}
      <div className='signup_quiz_sequence_selector_question_wrapper'>
        <span className='signup_quiz_sequence_selector_question'>
          What is your primary role?
        </span>
      </div>
      <div className='signup_quiz_sequence_selector_answers_wrapper signup_quiz_sequence_selector_answers_business_sequence_step_2_wrapper'>
          <AnswerCardComponent
            // title={"NOVICE"}
            selected={businessSequencesSelectedValues.exp}
            description={"Customer Service Team Leader"}
            imgSrc={roleTeamLeader}
            answerValue={"team leader"}
            onClick={() => {
              setBusinessSequencesSelectedValues({
                ...businessSequencesSelectedValues,
                exp: "team leader"
              });
            }}
          />
        <AnswerCardComponent
          // title={"MEDIUM"}
          selected={businessSequencesSelectedValues.exp}
          description={"CEO / Executive"}
          imgSrc={roleExecutive}
          answerValue={"executive"}
          onClick={() => {
            setBusinessSequencesSelectedValues({
              ...businessSequencesSelectedValues,
              exp: "executive"
            });
          }}
        />
        <AnswerCardComponent
          // title={"ADVANCED"}
          selected={businessSequencesSelectedValues.exp}
          description={"Product Manager"}
          imgSrc={roleProduct}
          answerValue={"product"}
          onClick={() => {
            setBusinessSequencesSelectedValues({
              ...businessSequencesSelectedValues,
              exp: "product"
            });
          }}
        />
        <AnswerCardComponent
          // title={"ADVANCED"}
          selected={businessSequencesSelectedValues.exp}
          description={"Live Chat / Support Agent"}
          imgSrc={roleAgent}
          answerValue={"agent"}
          onClick={() => {
            setBusinessSequencesSelectedValues({
              ...businessSequencesSelectedValues,
              exp: "agent"
            });
          }}
        />
         <AnswerCardComponent
          // title={"ADVANCED"}
          selected={businessSequencesSelectedValues.exp}
          description={"Developer"}
          imgSrc={roleDeveloper}
          answerValue={"developer"}
          onClick={() => {
            setBusinessSequencesSelectedValues({
              ...businessSequencesSelectedValues,
              exp: "developer"
            });
          }}
        />
        <AnswerCardComponent
          // title={"ADVANCED"}
          selected={businessSequencesSelectedValues.exp}
          description={"Other"}
          imgSrc={roleOther}
          answerValue={"other"}
          onClick={() => {
            setBusinessSequencesSelectedValues({
              ...businessSequencesSelectedValues,
              exp: "other"
            });
          }}
        />
      </div>
      <div
        className={
          "signup_quiz_mult_buttons_wrapper signup_quiz_business_mult_buttons_wrapper"
        }
      >
        {/* {currentSequences !== "company_type" && (
          <ButtonComponent
            className={"signup_quiz_button"}
            acuikit
            outline
            onClick={() => {
              setCurrentSequencesQiuzStep(0);
            }}
          >
            Back
          </ButtonComponent>
        )} */}
        <ButtonComponent
          className={"signup_quiz_button"}
          acuikit
          locked={!businessSequencesSelectedValues.exp}
          onClick={() => {
            sendAnalytics(setCurrentSequences("finalStep"));
          }}
        >
          Next
        </ButtonComponent>
      </div>
    </div>
  );
};

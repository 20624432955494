import React from "react";
import { Link } from "react-router-dom";
import svgLib from "../../../../functionLib/svglib";

export type Step2Props = { newBotId: string };
export const Step2: React.FC<Step2Props> = ({ newBotId }) => (
  <div className="new_bot_body_step_2">
    <div className="build_your_first_bot_goTo">
      <div style={{ width: 500 }} className="build_your_first_bot_goTo_item">
        <div className="build_your_first_bot_goTo_item_header">
          {svgLib.go_to_intents("")}
        </div>
        <div className="build_your_first_bot_goTo_item_desc">
          <p style={{ textAlign: "start" }}></p>
        </div>
        <div className="build_your_first_bot_goTo_item_button">
          <Link
            className="build_your_first_bot_button_wrapper"
            to={`/bots/bot/${newBotId}/automation/skills`}
          >
            <div>
              <span style={{ fontSize: 12 }}>Test your chatbot</span>
            </div>
          </Link>
        </div>
      </div>
    </div>
  </div>
);

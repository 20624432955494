import React from "react";

export type HeaderProps = {
  currentStep: number;
  steps: number[];
};
export const Header: React.FC<HeaderProps> = ({ currentStep, steps }) => (
  <div className="new_bot_header">
    <div className="new_bot_header_title">
      <span>{getHeaderTitle(currentStep)}</span>
    </div>
    <div className="new_bot_header_steps">
      {steps.map((item) => (
        <div
          key={item}
          className="new_bot_header_step_item"
          style={{
            background: `${item === currentStep ? "#006CFF" : "#B0B0B0"}`,
          }}
        />
      ))}
    </div>
    <div className="new_bot_header_desc">
      <span>{getHeaderDesc(currentStep)}</span>
    </div>
  </div>
);
const getHeaderDesc = (stepValue: number) => {
  switch (stepValue) {
    case 0:
      return "Start from scratch or copy an existing project";
    case 1:
      return "Which language should your new agent speak?";
    case 2:
      return "Now let's test your bot";
    default:
      return "";
  }
};
const getHeaderTitle = (stepValue: number) => {
  switch (stepValue) {
    case 0:
      return "Create new AI bot";
    case 1:
      return "Create new AI bot";
    case 2:
      return "Your agent is almost ready!";
    default:
      return "";
  }
};

import moment from 'moment';
import { v4 as uuid } from 'uuid';
import { Api } from '../../../../api';
import ac from './img/ac_symbol1.png';

export const copyToNewAgent = async (
    api: Api,
    cb = (agentId: string) => {},
    title: string,
    lang: string,
    originalImage,
    copyId: string
) => {
    try {
        let url;
        const trial_end = moment().add(14, 'days').unix() * 1000;
        if (originalImage && originalImage.file) {
            url = await api.uploadImageToGS(originalImage.file, uuid(), 'image', '', true, false);
        }

        const data = await api.agents.getById(copyId, 'json title image');
        const newAgent = await api.agents.create({
            advancedSettings: {
                bot_language: lang,
            },
            trial_end,
            title,
            image: (url && url.url) || ac,
            json: { raw: data.json.raw },
        });
        await api.agents.saveFlowJson(newAgent.data._id, JSON.parse(newAgent.data.json.raw));
        cb(newAgent.data._id);

        window.analytics.track('Bot Added', {
            Platform: 'Architect',
            Bot_Name: title,
        });

        window.notification.info('Agent has been created', 5);
    } catch (error) {
        console.log(error);
        window.notification.error(error.message, 5);
        throw new Error(error);
    }
};

export const createNewAgentFromScratch = async (
    api: Api,
    cb = (agentId: string) => {},
    title,
    lang,
    originalImage?
) => {
    try {
        let urlWrapper: { url: string } | undefined = undefined;
        const trial_end = moment().add(14, 'days').unix() * 1000;
        if (originalImage && originalImage.file) {
            urlWrapper = await api.uploadImageToGS(originalImage.file, uuid(), 'image', '', true, false);
        }
        const newAgent = await api.agents.create({
            advancedSettings: {
                bot_language: lang,
            },
            trial_end,
            title,
            image: (urlWrapper && urlWrapper.url) || ac,
        });
        cb(newAgent.data._id);
        window.notification.info('Agent has been created', 5);

        window.analytics.track('Bot Added', {
            Platform: 'Architect',
            Bot_Name: title,
        });
    } catch (error) {
        console.log(error);
        window.notification.error(error.message, 5);
        throw new Error(error);
    }
};

import { CustomerType } from "../types/customer";
import { CustomerPermission } from "../types/customerRole";

// eslint-disable-next-line import/prefer-default-export
export const rolesRestrictionsContentGenerator = (
  subRoute: { name: string; linkTo: string },
  customerPermissions: CustomerPermission[],
  allPermissions: CustomerPermission[],
  customer: CustomerType | null
) => {
  const userPermissions =
    customerPermissions.length > 0 ? customerPermissions : allPermissions;
  let available = false;
  switch (subRoute.name) {
    default:
      available = false;
      break;
    case "Charts":
      if (userPermissions.indexOf("analytics") > -1) available = true;
      break;
    case "Broadcasts":
      if (userPermissions.indexOf("conversations") > -1) available = true;
      break;
    case "General":
    case "Roles":
      if (userPermissions.indexOf("admin") > -1) available = true;
      break;
    case "Billing":
      if (userPermissions.indexOf("billing") > -1) available = true;
      break;
    case "Welcome message":
    case "Keywords":
    case "Website messages":
    case "Skills":
    case "Knowledge":
      if (userPermissions.indexOf("edit_skills") > -1) available = true;
      // if (userPermissions.indexOf('view_skills') > -1) available = true;
      break;
    case "Dialogflow":
    case "Intents":
    case "Insights":
      if (userPermissions.indexOf("edit_skills") > -1) available = true;
      // if (userPermissions.indexOf('view_skills') > -1) available = true;
      break;
    case "Assistants":
      if (userPermissions.indexOf("edit_skills") > -1) available = true;
      // if (userPermissions.indexOf('view_skills') > -1) available = true;
      break;
    case "Channels":
    case "Grow tools":
      if (userPermissions.indexOf("channels") > -1) available = true;
      break;
    case "Agents":
      if (userPermissions.indexOf("livechat_admin") > -1) available = true;
      break;
    case "Integrations":
      if (userPermissions.indexOf("integrations") > -1) available = true;
      break;
    case "Chats":
      if (userPermissions.indexOf("conversations") > -1) available = true;
      if (userPermissions.indexOf("livechat_agent") > -1) available = true;
      if (userPermissions.indexOf("livechat_admin") > -1) available = true;
      break;
  }
  // NOTE(m-nny): customer.isAdmin is strange, because api.customer is passed actually
  if (customer?.isAdmin) {
    available = true;
  }
  return available;
};

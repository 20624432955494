import React, { useEffect, useState } from "react";
import "./SignUpComponentV2.css";
import WizzardComponent from "../WizzardComponent/WizzardComponent";
import new_logo from "./img/new_logo.png";
const SignUpComponentV2 = () => {
  return (
    <div className={"signup_component_v2"}>
      <header>
        <img
          src={new_logo}
          alt=''
          onClick={() => {
            window.location = "/";
          }}
        />
      </header>
      <WizzardComponent />
    </div>
  );
};

export default SignUpComponentV2;

import React, { useEffect, useState } from "react";
import { useConfig } from "../../../api/config";
import svgLib from "../../../functionLib/svglib";
import { useAppBot } from "../../../redux/bot/bot.selectors";
import { useUnsafeCustomer } from "../../../redux/customer/customer.selectors";
import Dropdown from "../Dropdown/Dropdown";
import "./AppHeaderComponent.css";
import facebook from "./img/facebook.png";
import telegram from "./img/telegram.png";
import whatsapp from "./img/whatsapp.png";

type ButtonItem = {
  content: React.ReactElement;
  value: string;
  name: string;
  onClick: () => void;
};
export type AppHeaderComponentProps = {
  leftButton?: React.ReactElement;
  rightButton?: React.ReactElement;
  text?: string | React.ReactElement;

  root?: string;
  subRoot?: string;
  setShowSidebar: (value: boolean) => void;
};

const AppHeaderComponent: React.FC<AppHeaderComponentProps> = ({
  leftButton,
  rightButton,
  text,
  root,
  subRoot,
  setShowSidebar,
}) => {
  const config = useConfig();
  const { botAgent: selectedBot } = useAppBot();
  const { customerRoles } = useUnsafeCustomer();
  const [buttons, setButtons] = useState<ButtonItem[]>([]);
  const [tgName, setTgName] = useState([]);
  const [trialDays, setTrialDays] = useState(1);
  const [isBotAdmin, setIsBotAdmin] = useState(false);

  const getTelegramName = () => {
    if (selectedBot?.messengers.telegram?.token) {
      const settings = {
        async: true,
        crossDomain: true,
        url: `https://api.telegram.org/bot${selectedBot.messengers.telegram.token}/getMe`,
        method: "GET",
        headers: {
          "cache-control": "no-cache",
          "postman-token": "b15a746e-eaba-e0bc-d44d-56704d8972b8",
        },
      };

      window.jQuery.ajax(settings).done((response) => {
        setTgName(response.result.username);
      });
    }
  };

  const createTestButtons = () => {
    const buttonOptions: ButtonItem[] = [];
    if (selectedBot?.messengers) {
      Object.keys(selectedBot.messengers).forEach((item, i) => {
        if (item === "facebook") {
          const facebookId = Object.keys(selectedBot.messengers.facebook!)[0];
          buttonOptions.push({
            content: (
              <div
                key={i}
                className="test_button_group_bot_select_option"
                onClick={() =>
                  window.open(`https://m.me/${facebookId}`, "_blank")
                }
              >
                <img src={facebook} />
                <span className="test_button_group_bot_select_option_title">
                  Facebook
                </span>
              </div>
            ),
            value: "facebbok",
            name: "facebook",
            onClick: () => window.open(`https://m.me/${facebookId}`, "_blank"),
          });
        }
        if (item === "whatsapp") {
          const whatsappNumber = selectedBot?.messengers?.whatsapp?.number;
          whatsappNumber &&
            buttonOptions.push({
              content: (
                <div
                  key={i}
                  className="test_button_group_bot_select_option"
                  onClick={() =>
                    window.open(`https://wa.me/${whatsappNumber}`, "_blank")
                  }
                >
                  <img src={whatsapp} width="25px" height="25px" />
                  <span className="test_button_group_bot_select_option_title">
                    Whatsapp
                  </span>
                </div>
              ),
              value: "whatsapp",
              name: "whatsapp",
              onClick: () =>
                window.open(`https://wa.me/${whatsappNumber}`, "_blank"),
            });
        }
        if (item === "telegram" && selectedBot.messengers.telegram?.isActive) {
          buttonOptions.push({
            content: (
              <div
                key={i}
                className="test_button_group_bot_select_option"
                onClick={() => {
                  selectedBot.messengers &&
                    window.open(`https://t.me/${tgName}`, "_blank");
                }}
              >
                <img src={telegram} />{" "}
                <span className="test_button_group_bot_select_option_title">
                  Telegram
                </span>
              </div>
            ),
            value: "telegram",
            name: "telegram",
            onClick: () =>
              selectedBot.messengers &&
              window.open(`https://t.me/${tgName}`, "_blank"),
          });
        }
      });
      setButtons(buttonOptions);
    }
  };
  useEffect(() => {
    getTelegramName();
    createTestButtons();
  }, [selectedBot]);

  useEffect(() => {
    if (tgName) createTestButtons();
  }, [tgName]);

  useEffect(() => {
    if (!selectedBot?.title) {
      return;
    }
    if (typeof text === "string") {
      document.title = `Activechat ${text ? ` - ${text}` : ""}`;
    } else {
      document.title = `Activechat ${
        selectedBot.title ? ` - ${selectedBot.title}` : ""
      } ${root ? ` - ${root}` : ""} ${subRoot ? ` / ${subRoot}` : ""}`;
    }
  }, [selectedBot?.title]);

  useEffect(() => {
    if (!selectedBot) {
      return;
    }
    if (selectedBot.trial_end && selectedBot.trial_end > new Date().getTime()) {
      const difference = selectedBot.trial_end - new Date().getTime();
      setTrialDays(Math.floor(difference / 1000 / 60 / 60 / 24));
    }
  }, [selectedBot]);

  useEffect(() => {
    if (customerRoles && customerRoles.length && selectedBot) {
      const roles = customerRoles.filter(
        (element) =>
          (element.permissions.includes("admin") ||
            element.permissions.includes("billing")) &&
          element.botId === selectedBot._id
      );
      if (roles.length) setIsBotAdmin(true);
    }
  }, [customerRoles, selectedBot]);

  return (
    <div className="AppHeaderComponent_main">
      <div className="AppHeaderComponent_main_first">
        <div
          className="AppHeaderComponent_main_navbar_burger"
          onClick={() => setShowSidebar(true)}
        >
          {svgLib.burger("#000000", { width: "20px", height: "20px" })}
        </div>
        <div className="AppHeaderComponent_main_first_tr">
          <div className="AppHeaderComponent_main_bot_name">
            <span>{text}</span>
          </div>
          <div>
            {selectedBot?._id && (
              <Dropdown
                zIndex={3}
                className="test_bots"
                name="Test your chatbot"
                onNameClick={() =>
                  window.open(`${config.GATEWAY_URL}/cw/${selectedBot._id}`)
                }
                acuikit
                options={buttons}
              />
            )}
          </div>
        </div>
        <div className="AppHeaderComponent_main_second_tr">
          <div className="AppHeaderComponent_main_left_button">
            {leftButton}
          </div>
          <div className="AppHeaderComponent_main_notification">
            <div className="AppHeaderComponent_main_notification_wrapper">
              <div className="AppHeaderComponent_main_notification_jingle"></div>
              <div className="AppHeaderComponent_main_notification_education"></div>
            </div>
          </div>
          <div className="AppHeaderComponent_main_right_button">
            {rightButton}
          </div>
        </div>
      </div>
      {selectedBot &&
        selectedBot.plan === "free" &&
        selectedBot.trial_end &&
        selectedBot.trial_end > new Date().getTime() &&
        isBotAdmin &&
        selectedBot &&
        trialDays <= 3 &&
        selectedBot.status !== "freezed" && (
          <div className="AppHeaderComponent_main_first_trial">
            <div className="AppHeaderComponent_main_first_trial_title">
              <p>
                <span>There is only </span>
                <span id="trial_days_header">
                  {trialDays} {trialDays === 1 ? "day" : "days"}
                </span>
                left on your trial. Upgrade today to keep access to the live
                chat and other advanced features.
              </p>
            </div>
            <div className="AppHeaderComponent_main_first_trial_link">
              <a href={`/v2/bots/bot/${selectedBot._id}/settings/billing`}>
                Choose your plan now
              </a>
            </div>
          </div>
        )}

      {selectedBot && selectedBot.status === "freezed" && (
        <div className="AppHeaderComponent_main_first_trial">
          <div className="AppHeaderComponent_main_first_trial_title">
            <p>
              Your project is on hold now. Please choose the subscription plan
              to activate.
            </p>
          </div>
          <div className="AppHeaderComponent_main_first_trial_link">
            <a href={`/v2/bots/bot/${selectedBot._id}/settings/billing`}>
              Choose your plan now
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default AppHeaderComponent;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import validator from "validator";
import { useApi } from '../../../../../functionLib/hooks';
import ButtonComponent from "../../../../UIComponents/ButtonComponent/ButtonComponent";
import CheckBoxComponent from "../../../../UIComponents/CheckboxComponent/CheckboxComponent";
import { InformPopup } from "../../../../UIComponents/InputComponent/InformPopup";
import LoadingComponent from "../../../../UIComponents/LoadingComponent/LoadingComponent";
import ShowPassword from "./images/ShowPassword1.svg";
import "./SignUpQuizV2Component.css";


const SignUpQuizV2Component = ({ goToNextStep, setCustomerID }) => {
  const api = useApi();
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [agreement, setAgreement] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [message, setMessage] = useState({
    data: null,
    name: ""
  });
  const [login, setLogin] = useState(false);
  const [googleAuthURL, setGoogleAuthURL] = useState("");
  const [loading, setLoading] = useState(false);
  const [confirmStyle, setConfirmStyle] = useState({});
  const [popupColor, setPopupColor] = useState("");
  const [userData, setUserData] = useState({
    email,
    password,
    confirmPassword,
    firstName,
    lastName,
    agreement
  });
  const [locked, setLocked] = useState(true);

  const [validation, setValidation] = useState({
    lowercase: false,
    uppercase: false,
    numeric: false,
    special: false,
    passwordLength: false
  });
  const [showPasswordCheck, setShowPasswordCheck] = useState('hide')

  const [passwordValid, setPasswordValid] = useState(false);

  // Regexs to validate password
  const lowercase = /(?=.*[a-z])/;
  const uppercase = /(?=.*[A-Z])/;
  const numeric = /(?=.*[0-9])/;
  const special = /(?=.*[!@#\$%\^&\*])/;
  const passwordLength = /(?=.{8,})/;

  // Pasword validation on changes in password input
  useEffect(() => {
    passwordValidation();
  }, [password]);

  useEffect(() => {
    !Object.values(validation).includes(false) ? setPasswordValid(true) : setPasswordValid(false)
  }, [validation])



  // Setter for UserData on changes in email, password, firstName, lastName, confirmPassword, agreement inputs
  useEffect(() => {
    setUserData({
      email,
      password,
      confirmPassword,
      firstName,
      lastName,
      agreement
    });
    agreement ? setLocked(false) : setLocked(true)
  }, [email, password, firstName, lastName, confirmPassword, agreement]);

  // Password validations for requirments. Use preseted Regexs
  const passwordValidation = () => {
    let obj = {
      lowercase: false,
      uppercase: false,
      numeric: false,
      special: false,
      passwordLength: false
    };

    uppercase.test(password)
      ? (obj = { ...obj, uppercase: true })
      : (obj = { ...obj, uppercase: false });
    lowercase.test(password)
      ? (obj = { ...obj, lowercase: true })
      : (obj = { ...obj, lowercase: false });
    numeric.test(password)
      ? (obj = { ...obj, numeric: true })
      : (obj = { ...obj, numeric: false });
    special.test(password)
      ? (obj = { ...obj, special: true })
      : (obj = { ...obj, special: false });
    special.test(password)
      ? (obj = { ...obj, special: true })
      : (obj = { ...obj, special: false });
    passwordLength.test(password)
      ? (obj = { ...obj, passwordLength: true })
      : (obj = { ...obj, passwordLength: false });

    setValidation({ ...obj });

    // Object.keys(obj).map(element =>
    //   !element ? setPasswordValid(false) : setPasswordValid(true)
    // );
  };

  // Create message for error notifications in input fields and set border color for input
  let createMessage = (message, name, color) => {
    setMessage({
      data: message,
      name: name
    });

    color && setPopupColor(color);
  };

  // Function that checks user input data then sends an email to verify and then creates an account
  let onCreateAcc = (e, dataAccount) => {
    e.preventDefault();

    if (!String(dataAccount.email).trim().length) {
      setLocked(false)
      return createMessage("Please fill out this field", "email");
    }

    if (!validator.isEmail(email)) {
      setLocked(false)
      return createMessage(
        "We don’t support e-mails of this type of domain, please use another one",
        "email"
      );
    }

    if (!String(dataAccount.firstName).trim().length) {
      setLocked(false)
      return createMessage("Please fill out this field", "firstName");
    }

    if (!String(dataAccount.lastName).trim().length) {
      setLocked(false)
      return createMessage("Please fill out this field", "lastName");
    }

    if (!String(dataAccount.password).trim().length) {
      setLocked(false)
      return createMessage("Please fill out this field", "pass");
    }

    if (!String(dataAccount.confirmPassword).trim().length) {
      setLocked(false)
      return createMessage("Please fill out this field", "confirmPass");
    }
    console.log(!dataAccount.agreement);

    if (!passwordValid) {
      createMessage("Wrong password format", "pass");
      setLocked(false)
      return
    }

    if (dataAccount.password !== dataAccount.confirmPassword) {
      console.log(
        "password does not match",
        dataAccount.password,
        dataAccount.confirmPassword,
        dataAccount.password === dataAccount.confirmPassword
      );
      createMessage("Passwords do not match", "confirmPass");
      setConfirmStyle({ borderColor: "#e68f8f" });
      setLocked(false)
      return;
    }

    setConfirmStyle({});

    setLoading(true);

    api.authorize
      .validateEmail(userData)
      .then(() => {
        return api.authorize.newCustomerSignup(userData);
      })
      .then(customerCreated => {
        setLoading(false);
        setCustomerID(customerCreated._id);
        console.log("Customer created: ", customerCreated._id, customerCreated);
        window.analytics.track("Wizard Step Completed", {
          Platform: "Architect",
          Wizard: "Jan 2020 steps:",
          Step: "Create Account",
          "Step result": "Completed"
        });
        goToNextStep();
      })
      .catch((error = {}) => {
        setLoading(false);
        setLocked(false)
        console.log(error);
        if (error.existsCustomer) {
          window.feedBackPopUp("r", error.message, 3);
          return false;
        }

        return error.message && error.emailError
          ? createMessage(error.message, "email", "#ff6f61")
          : window.feedBackPopUp("r", error.message, 3);
      });
  };

  // Function that is called when closing InformPopup. Clears message for InformPopup
  let onSetShow = value => {
    setMessage({
      ...message,
      name: ""
    });
  };

  return (
    <div className='CreateNewAccount'>
      {loading && (
        <div className={"signup_loading"}>
          <LoadingComponent />
        </div>
      )}
      <form className='CreateNewAccount_container'>
        <div className='CreateNewAccount_Header'>Create your free account</div>
        <div className='CreateNewAccount_Link'>
          <p>
            Already have one?
            <Link to={"/login"} style={{ color: "rgba(43, 43, 43, 0.45)" }}>
              {` Log in here`}
            </Link>
          </p>
        </div>
        {
          <InformPopup
            className={"signup_quiz_v2"}
            offZIndex={true}
            customTop={[50, 13]}
            view={
              <div className='CreateNewAccount_Email'>
                <div className='CreateNewAccount_EmailLabel'>Email</div>
                <input
                  onChange={e => setEmail(e.target.value)}
                  className='CreateNewAccount_EmailInput'
                  placeholder='example@email.com'
                />
              </div>
            }
            show={message.name === "email"}
            setShow={onSetShow}
            description={
              <div className='informPOpup_errorMessage'>{message.data}</div>
            }
          />
        }
        {/* <div className='CreateNewAccount_Email'>
          <div className='CreateNewAccount_EmailLabel'>Email</div>
          <input
            onChange={e => setEmail(e.target.value)}
            className='CreateNewAccount_EmailInput'
            placeholder='example@email.com'
          />
        </div> */}
        <div className='CreateNewAccount_Name'>
          {
            <InformPopup
              className={"signup_quiz_v2 signup_quiz_v2_name"}
              offZIndex={true}
              customTop={[50, 13]}
              view={
                <div className='CreateNewAccount_FirstName'>
                  <div className='CreateNewAccount_FirstNameLabel'>
                    First Name
                  </div>
                  <input
                    maxLength='25'
                    onChange={e => setFirstName(e.target.value)}
                    placeholder='John'
                  />
                </div>
              }
              show={message.name === "firstName"}
              setShow={onSetShow}
              description={
                <div className='informPOpup_errorMessage'>{message.data}</div>
              }
            />
          }
          {
            <InformPopup
              className={"signup_quiz_v2 signup_quiz_v2_name"}
              offZIndex={true}
              customTop={[50, 13]}
              view={
                <div className='CreateNewAccount_LastName'>
                  <div className='CreateNewAccount_LastNameLabel'>
                    Last Name
                  </div>
                  <input
                    maxLength='25'
                    onChange={e => setLastName(e.target.value)}
                    placeholder='Smith'
                  />
                </div>
              }
              show={message.name === "lastName"}
              setShow={onSetShow}
              description={
                <div className='informPOpup_errorMessage'>{message.data}</div>
              }
            />
          }
          {/* <div className='CreateNewAccount_FirstName'>
            <div className='CreateNewAccount_FirstNameLabel'>First Name</div>
            <input
              maxLength='25'
              onChange={e => setFirstName(e.target.value)}
              placeholder='John'
            />
          </div> */}
          {/* 
          <div className='CreateNewAccount_LastName'>
            <div className='CreateNewAccount_LastNameLabel'>Last Name</div>
            <input
              maxLength='25'
              onChange={e => setLastName(e.target.value)}
              placeholder='Smith'
            />
          </div> */}
        </div>
        {
          <div className="Password_signup_quiz_v2">
            <InformPopup
              className={"signup_quiz_v2"}
              offZIndex={true}
              customTop={[50, 13]}
              view={
                <div tabindex="1" className='CreateNewAccount_Password'>
                  <div tabindex="1" className='CreateNewAccount_CreatePassword'>
                    <div className='CreateNewAccount_CreatePasswordLabel'>
                      Create Password
                  </div>
                    <input
                      className='CreateNewAccount_CreatePasswordInput'
                      style={confirmStyle}
                      type={showPassword ? "text" : "password"}
                      onChange={e => {
                        setPassword(e.target.value);
                      }}
                      onFocus={() => setShowPasswordCheck('show')}
                      onBlur={() => setShowPasswordCheck('hide')}
                      placeholder='password'
                    />
                    <div
                      onClick={() => setShowPassword(!showPassword)}
                      className='CreateNewAccount_ShowPassword'
                    >
                      <img src={ShowPassword} />
                    </div>
                  </div>
                </div>
              }
              show={message.name === "pass"}
              setShow={onSetShow}
              description={
                <div className='informPOpup_errorMessage'>{message.data}</div>
              }
            />
            {/* <div className='CreateNewAccount_Password'>
          <div className='CreateNewAccount_CreatePassword'>
            <div className='CreateNewAccount_CreatePasswordLabel'>
              Create Password
            </div>
            <input
              type={showPassword ? "text" : "password"}
              onChange={e => {
                setPassword(e.target.value);
              }}
              placeholder='password'
            />
            <div
              onClick={() => setShowPassword(!showPassword)}
              className='CreateNewAccount_ShowPassword'
            >
              <img src={ShowPassword} />
            </div>
          </div>
        </div> */}
            <div className={`CreateNewAccount_PasswordValidation_${showPasswordCheck} ${passwordValid ? 'password_valid' : ''}`}>
              <div className='Validation_Container'>
                <div
                  className={
                    validation.lowercase
                      ? "Validation_Checkbox_Confirmed"
                      : "Validation_Checkbox"
                  }
                ></div>
            One lowercase character
          </div>

              <div className='Validation_Container'>
                <div
                  className={
                    validation.uppercase
                      ? "Validation_Checkbox_Confirmed"
                      : "Validation_Checkbox"
                  }
                ></div>
            One uppercase character
          </div>

              <div className='Validation_Container'>
                <div
                  className={
                    validation.numeric
                      ? "Validation_Checkbox_Confirmed"
                      : "Validation_Checkbox"
                  }
                ></div>
            One number
          </div>

              <div className='Validation_Container'>
                <div
                  className={
                    validation.special
                      ? "Validation_Checkbox_Confirmed"
                      : "Validation_Checkbox"
                  }
                ></div>
            One special character
          </div>

              <div className='Validation_Container'>
                <div
                  className={
                    validation.passwordLength
                      ? "Validation_Checkbox_Confirmed"
                      : "Validation_Checkbox"
                  }
                ></div>
            8 characters minimum
          </div>
            </div>
          </div>
        }
        {
          <InformPopup
            className={"signup_quiz_v2"}
            offZIndex={true}
            customTop={[50, 13]}
            view={
              <div className='CreateNewAccount_ConfirmPassword'>
                <div className='CreateNewAccount_ConfirmPasswordLabel'>
                  Confirm password
                </div>
                <input
                  style={confirmStyle}
                  onChange={e => setConfirmPassword(e.target.value)}
                  type='password'
                  placeholder='confirm password'
                />
              </div>
            }
            show={message.name === "confirmPass"}
            setShow={onSetShow}
            description={
              <div className='informPOpup_errorMessage'>{message.data}</div>
            }
          />
        }
        {/* <div className='CreateNewAccount_ConfirmPassword'>
          <div className='CreateNewAccount_ConfirmPasswordLabel'>
            Confirm password
          </div>
          <input
            onChange={e => setConfirmPassword(e.target.value)}
            type='password'
            placeholder='confirm password'
          />
        </div> */}

        <div className='CreateNewAccount_CheckboxContainer'>
          <CheckBoxComponent
            checked={agreement}
            onChange={() => setAgreement(!agreement)}
          />
          <p>
            I agree to {" "}
            <a
              id='Agreement_link'
              onClick={() => {
                window.open(
                  "https://activechat.ai/terms-of-service/",
                  "popup",
                  "width=600,height=600"
                );
                return false;
              }}
              href='https://activechat.ai/privacy-policy/'
              target='popup'
            >
              {" "}
              Terms of service
            </a>{" "}
            and{" "}
            <a
              id='Agreement_link'
              onClick={() => {
                window.open(
                  "https://www.iubenda.com/privacy-policy/15807092",
                  "popup",
                  "width=600,height=600"
                );
                return false;
              }}
              href='https://www.iubenda.com/privacy-policy/15807092'
              target='popup'
            >
              Privacy Policy
            </a>
          </p>
        </div>

        <ButtonComponent
          locked={locked}
          onClick={e => {
            setLocked(true)
            onCreateAcc(e, userData);
          }}
          acuikit
          className={"button_component_bold_text"}
        >
          Create account
        </ButtonComponent>
      </form>
    </div>
  );
};

export default SignUpQuizV2Component;

import qs from 'qs';
import { UsersFilterValue } from '../../redux/users/users.types';
import { SessionStatus } from '../../types/livachat';
import { UserData } from '../../types/userData';
import { ApiConfig } from '../config';
import utils from '../utils';
import {
    GetByAgentIdResult,
    GetByAgentIdSettings,
    GetSessionsResult,
    DataMessageAnalyticsInterface,
    DataPerformanceInterface,
    DataConversationsInterface,
    DataSessionsInterface,
} from './users.types';
import connect from '../index';

const users = (options: ApiConfig) => {
    const { GATEWAY_URL, DEFAULT_API_ERROR, USER_SERVICE_URL, ARCHITECT_URL } = options;

    /**
     * Gets the user by id
     * @param {String} agentID - Id of the agent to get by
     * @param {String} userID - Id of the user to get by
     * @param {(Object|String)} projection - Projection of the user's fields to get
     * @returns {Promise} user by specified id
     */
    const getById = async (agentID, userID, projection) => {
        if (!userID) throw new Error('Please specify user id');

        const url = new URL(`${GATEWAY_URL}/api/users/${userID}`);

        url.searchParams.append('agentID', agentID);

        if (projection) {
            if (typeof projection === 'string') url.searchParams.append('fields', projection);
            else url.searchParams.append('fields', utils._parseFieldsObjectToString(projection));
        }

        const options: RequestInit = {
            headers: {
                'Content-Type': 'application/json',
            },
            mode: 'cors',
            credentials: 'include',
            method: 'GET',
        };

        try {
            const response = await fetch(url.toString(), options);
            const statusCode = response.status;
            const body = await response.json();

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    /**
     * Get dialog by user id
     * @param {String} agentID - Id of the agent to get by
     * @param {String} userID - Id of the user to get by
     * @returns {Promise} user by specified id
     */
    const getDialogById = async (agentID, userID, settings, fiterEvents) => {
        if (!userID) throw new Error('Please specify user id');
        if (!agentID) throw new Error('Please specify bot id');
        let qs = '';

        if (settings) {
            qs = new URLSearchParams(settings).toString();
        }
        const url = new URL(
            `${USER_SERVICE_URL}/api/v1/users/${userID}/messages?botId=${agentID}&${qs}${
                !fiterEvents ? '&types_exclude=event' : ''
            }`
        );
        // const url = new URL(`${USER_SERVICE_URL}/api/v1//api/v1/users/:userId/messages?botId=${agentID}&${qs}`)

        const options: RequestInit = {
            headers: {
                'Content-Type': 'application/json',
            },
            // mode: 'cors',
            // credentials: 'include',
            method: 'GET',
        };

        try {
            const response = await fetch(url.toString(), options);
            const statusCode = response.status;
            const body = await response.json();
            // TODO 404
            if (statusCode === 404) return { messages: [] };
            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };
    /**
     * Gets users by agent id specified
     */
    const getByAgentId = async (
        agentID: string,
        settings: GetByAgentIdSettings,
        attach?: string,
        filter?: UsersFilterValue
    ): Promise<GetByAgentIdResult> => {
        if (!agentID) throw new Error('Please specify agent id');
        const query = new URLSearchParams(settings as any).toString();

        const filterQs = filter ? '&' + qs.stringify({ filter }) : '';

        const url = new URL(
            `${USER_SERVICE_URL}/api/v1/users?botId=${agentID}&${query}${attach ? `&attach=${attach}` : ''}${filterQs}`
        );

        const options: RequestInit = {
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'GET',
        };

        try {
            const response = await fetch(url.toString(), options);
            const statusCode = response.status;
            const body = await response.json();

            if (statusCode === 404)
                return {
                    users: [],
                    returnedUsers: 0,
                    usersWithFilterTotalCount: 0,
                    userDatasTotalCount: 0,
                    usersWithoutFilterTotalCount: 0,
                };
            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body || {};
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    const getUserData = async (userId: string): Promise<UserData> => {
        if (!userId) throw new Error('Please specify userId');

        const url = new URL(`${USER_SERVICE_URL}/api/v1/users/${userId}/userdata`);

        const options: RequestInit = {
            headers: {
                'Content-Type': 'application/json',
            },
            // mode: 'cors',
            // credentials: 'include',
            method: 'GET',
        };

        try {
            const response = await fetch(url.toString(), options);

            const statusCode = response.status;
            const body = await response.json();
            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return (body || {}).userdata;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    const updateTags = async (botId, userId, tags) => {
        if (!userId) throw new Error('Please specify userId');
        if (!botId) throw new Error('Please specify botId');
        if (!tags) throw new Error('Please specify tags');

        const url = new URL(`${USER_SERVICE_URL}/api/v1/users/${userId}/userdata/tags?botId=${botId}`);

        const options: RequestInit = {
            headers: {
                'Content-Type': 'application/json',
            },
            // mode: 'cors',
            // credentials: 'include',
            method: 'PUT',
            body: JSON.stringify({ tags }),
        };

        try {
            const response = await fetch(url.toString(), options);

            const statusCode = response.status;
            const body = await response.json();
            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return (body || {}).userdata;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    /**
     * Counts users quantity that passes the filter specified
     * @param {{
     * channel: String,
     * filter: Object,
     * agentId: String
     * }} filter - Object which determines parameters of the filter to count users quantity that pass
     * @returns {Promise} object with count of matched users
     */
    const quantity = async (filter: { channel: String; filter: any; agentId: String }) => {
        if (!filter.agentId) throw new Error('Please specify id of the agent to count users for');

        const url = new URL(`${GATEWAY_URL}/api/getQty`);

        const options: RequestInit = {
            headers: {
                'Content-Type': 'application/json',
            },
            mode: 'cors',
            credentials: 'include',
            method: 'POST',
            body: JSON.stringify({ data: filter }),
        };

        try {
            const response = await fetch(url.toString(), options);
            const statusCode = response.status;
            const body = await response.json();

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return (body || {}).usersQty;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    /**
     * Eraise users
     * @param {String} agentID - Id of the agent to delete users
     * @param {Array} userIDs - array of users ID
     * @returns {Promise} users of the agent with specified id
     */
    const eraseUser = async (agentID, userIDs) => {
        if (!agentID) throw new Error('Please specify id of the agent to delete users');

        const url = new URL(`${USER_SERVICE_URL}/api/v1/users?botId=${agentID}`);

        // const url = new URL(`${GATEWAY_URL}/api/eraseUser`);

        const options: RequestInit = {
            headers: {
                'Content-Type': 'application/json',
            },
            // mode: 'cors',
            // credentials: 'include',
            method: 'DELETE',
            body: JSON.stringify({ users: userIDs }),
        };

        try {
            const response = await fetch(url.toString(), options);
            const statusCode = response.status;
            const body = await response.json();

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body || {};
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };
    const getAgentSessions = async (botId, agentId, sessionStatus) => {
        if (!agentId) throw new Error('Please specify agent id');
        const url = new URL(
            `${USER_SERVICE_URL}/api/v1/sessions/agent?ids=${agentId}&botId=${botId}&status=${sessionStatus}&attach=user`
        );
        const options: RequestInit = {
            headers: {
                'Content-Type': 'application/json',
            },
            // mode: 'cors',
            // credentials: 'include',
            method: 'GET',
        };

        try {
            const response = await fetch(url.toString(), options);
            const statusCode = response.status;
            const body = await response.json();

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body || {};
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    const getSessions = async (
        botId: string,
        agentId: string | null,
        sessionStatus: SessionStatus,
        tags?: string[]
    ): Promise<GetSessionsResult> => {
        const url = new URL(
            `${USER_SERVICE_URL}/api/v1/users/sessions?${agentId ? `agentId=${agentId}&` : ''}${
                tags && tags?.length > 0 ? `tags=${tags.join(',')}&` : ''
            }botId=${botId}&status=${sessionStatus}&limit=200&attach=lastMessage,name,tags,escalated${
                sessionStatus === 'closed' || sessionStatus === 'abandoned' ? '&sort=-1' : ''
            }`
        );
        const options: RequestInit = {
            headers: { 'Content-Type': 'application/json' },
            method: 'GET',
        };

        try {
            const response = await fetch(url.toString(), options);
            const statusCode = response.status;
            const body = await response.json();

            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };

            return body || {};
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };
    const getMessagesAnalytics = async (
        botId: string,
        before: number | undefined,
        after: number | undefined
    ): Promise<DataMessageAnalyticsInterface> => {
        const url = new URL(
            `${USER_SERVICE_URL}/api/v1/users/messages/all?botId=${botId}${before ? `&before=${before}` : ''}${
                after ? `&after=${after}` : ''
            }`
        );
        const options: RequestInit = {
            headers: { 'Content-Type': 'application/json' },
            method: 'GET',
        };
        try {
            const response = await fetch(url.toString(), options);
            const { messages } = await response.json();
            const reducedData = messages.reduce((acc, el) => {
                const from = el.source.reduce((a, e) => {
                    a[e.from[0].toUpperCase() + e.from.slice(1)] = e.count;
                    return a;
                }, {});
                acc.push({ date: el._id.slice(0, 5), ...from });
                return acc;
            }, []);

            let data;
            if (after && !before) {
                data = utils._ArrayOfOneDate(after);
                reducedData.forEach((el) => {
                    data.forEach((e, i) => {
                        if (el.date === e.date) {
                            data.splice(i, 1, el);
                        }
                    });
                });
            }
            if (after && before) {
                data = utils._ArrayOfTwoDate(after, before);
                reducedData.forEach((el) => {
                    data.forEach((e, i) => {
                        if (el.date === e.date) {
                            data.splice(i, 1, el);
                        }
                    });
                });
            }
            const csv = data.reduce((acc, el, i) => {
                if (i === 0) {
                    acc.push(['Date', 'Bot', 'User', 'Agent']);
                }
                acc.push(Object.values(el));
                return acc;
            }, []);
            return { data, csv } || {};
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    const getConversationUsersAnalytics = async (
        botId: string,
        before: number | undefined,
        after: number | undefined
    ): Promise<DataConversationsInterface> => {
        const url = new URL(
            `${USER_SERVICE_URL}/api/v1/users/analytics/conversation?botId=${botId}${
                before ? `&before=${before}` : ''
            }${after ? `&after=${after}` : ''}`
        );
        const options: RequestInit = {
            headers: { 'Content-Type': 'application/json' },
            method: 'GET',
        };
        try {
            const response = await fetch(url.toString(), options);
            const result = await response.json();

            const reducedData = result.map((e) => {
                return {
                    date: e._id.slice(0, 5),
                    'New leads': e.new_leads,
                    Conversations: e.conversations,
                };
            });
            let data;
            if (after && !before) {
                data = utils._ArrayOfOneDate(after);
                reducedData.forEach((el) => {
                    data.forEach((e, i) => {
                        if (el.date === e.date) {
                            data.splice(i, 1, el);
                        }
                    });
                });
            }
            if (after && before) {
                data = utils._ArrayOfTwoDate(after, before);
                reducedData.forEach((el) => {
                    data.forEach((e, i) => {
                        if (el.date === e.date) {
                            data.splice(i, 1, el);
                        }
                    });
                });
            }
            const csv = data.reduce((acc, el, i) => {
                if (i === 0) {
                    acc.push(['Date', 'New leads', 'Conversations']);
                }
                acc.push(Object.values(el));
                return acc;
            }, []);

            return { data, csv } || {};
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };
    const getSessionsAnalytics = async (
        botId: string,
        before: number | undefined,
        after: number | undefined
    ): Promise<DataSessionsInterface> => {
        const url = new URL(
            `${USER_SERVICE_URL}/api/v1/sessions/analytics?botId=${botId}${before ? `&before=${before}` : ''}${
                after ? `&after=${after}` : ''
            }`
        );
        const options: RequestInit = {
            headers: { 'Content-Type': 'application/json' },
            method: 'GET',
        };
        try {
            const response = await fetch(url.toString(), options);
            const { sessions } = await response.json();

            const reducedData = sessions.reduce((acc, e) => {
                const from = e.source.reduce((acc, e) => {
                    if (e.status === 'closed') {
                        acc.Successful = e.count;
                    }
                    if (e.status === 'abandoned' || e.status === 'queued') {
                        acc.Abandoned = e.count;
                    }
                    return acc;
                }, {});
                acc.push({ date: e._id.slice(0, 5), ...from });
                return acc;
            }, []);
            let data;
            if (after && !before) {
                data = utils._ArrayOfOneDate(after);
                reducedData.forEach((el) => {
                    data.forEach((e, i) => {
                        if (el.date === e.date) {
                            data.splice(i, 1, el);
                        }
                    });
                });
            }
            if (after && before) {
                data = utils._ArrayOfTwoDate(after, before);
                reducedData.forEach((el) => {
                    data.forEach((e, i) => {
                        if (el.date === e.date) {
                            data.splice(i, 1, el);
                        }
                    });
                });
            }
            const csv = data.reduce((acc, el, i) => {
                if (i === 0) {
                    acc.push(['Date', 'Successful', 'Abandoned']);
                }
                acc.push(Object.values(el));
                return acc;
            }, []);
            return { data, csv } || {};
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    const getPerformanceAnalytics = async (
        botId: string,
        before: number | undefined,
        after: number | undefined
    ): Promise<DataPerformanceInterface> => {
        const url = new URL(
            `${USER_SERVICE_URL}/api/v1/sessions/analytics/performance?botId=${botId}${
                before ? `&before=${before}` : ''
            }${after ? `&after=${after}` : ''}`
        );
        const options: RequestInit = {
            headers: { 'Content-Type': 'application/json' },
            method: 'GET',
        };
        try {
            const response = await fetch(url.toString(), options);
            const { sessions } = await response.json();
            const array = sessions.reduce(
                (acc, e, i) => {
                    connect()
                        .livechat.getAgentById(e?._id)
                        .then(({ agent }) => {
                            sessions[i].name = agent?.customerMeta?.name;
                            const result: string[] = [
                                `${agent?.customerMeta?.name}`,
                                `${e?.sessionsCount}`,
                                `${e?.avgFirstResponseMinutes}min ${e?.avgFirstResponseSeconds}sec`,
                                `${e?.avgResponseMinutes}min ${e?.avgResponseSeconds}sec`,
                                `${e?.avgSessionLengthMinutes}min ${e?.avgSessionLengthSeconds}sec`,
                            ];
                            acc?.push(result);
                        });
                    return acc;
                },
                [['Name', 'Sessions', 'FRT', 'ART', 'Duration']]
            );
            const csv = await array;
            return { sessions, csv } || [];
        } catch (error) {
            throw error.statusCode ? error : { message: 'Error in performance analytics request' };
        }
    };

    const createFineTuning = async (body) => {
        const url = new URL(`${USER_SERVICE_URL}/api/v1/fine_tuning`);
        const options: RequestInit = {
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify(body),
        };

        try {
            const response = await fetch(url.toString(), options);
            const statusCode = response.status;
            const body = await response.json();
            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    const getFineTuningDataMessage = async (id) => {
        const url = new URL(`${USER_SERVICE_URL}/api/v1/fine_tuning?id=${id}`);
        const options: RequestInit = {
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'GET',
        };

        try {
            const response = await fetch(url.toString(), options);
            const statusCode = response.status;
            const body = await response.json();
            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };
            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    const createOrUpdateFineTuningData = async (body) => {
        const url = new URL(`${USER_SERVICE_URL}/api/v1/fine_tuning/fromMessage`);
        const options: RequestInit = {
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify(body),
        };

        try {
            const response = await fetch(url.toString(), options);
            const statusCode = response.status;
            const body = await response.json();
            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };
            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    const createKnowledge = async (body) => {
        const url = new URL(`${USER_SERVICE_URL}/api/v1/knowledge`);
        const options: RequestInit = {
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify(body),
        };

        try {
            const response = await fetch(url.toString(), options);
            const statusCode = response.status;
            const body = await response.json();
            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };
            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };
    const deleteKnowledge = async (body) => {
        const url = new URL(`${USER_SERVICE_URL}/api/v1/knowledge`);
        const options: RequestInit = {
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'DELETE',
            body: JSON.stringify(body),
        };

        try {
            const response = await fetch(url.toString(), options);
            const statusCode = response.status;
            const body = await response.json();
            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };
            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };
    const getKnowledgeByIds = async (body) => {
        const { botId, customerId } = body;
        const url = new URL(
            `${USER_SERVICE_URL}/api/v1/knowledge?botId=${botId}${customerId ? `&customerId=${customerId}` : ''}`
        );
        const options: RequestInit = {
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'GET',
        };

        try {
            const response = await fetch(url.toString(), options);
            const statusCode = response.status;
            const body = await response.json();
            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };
            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };
    const loadKnowledgeForMetaDialog = async (id) => {
        const url = new URL(`${ARCHITECT_URL}/agent/metadialog/files?id=${id}`);
        const options: RequestInit = {
            method: 'POST',
        };

        try {
            const response = await fetch(url.toString(), options);
            const statusCode = response.status;
            const body = await response.json();
            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };
            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };

    const getLeadsCountOfUsers = async (id) => {
        const url = new URL(`${USER_SERVICE_URL}/api/v1/users/count?botId=${id}`);
        const options: RequestInit = {
            method: 'GET',
        };

        try {
            const response = await fetch(url.toString(), options);
            const statusCode = response.status;
            const body = await response.json();
            if (body && body.error && typeof body.error === 'string') body.error = { message: body.error };

            if (!response.ok) {
                throw {
                    statusCode,
                    message: DEFAULT_API_ERROR,
                    ...body.error,
                    rawBodyTaken: body,
                };
            }
            if (body && body.error) throw { statusCode, message: DEFAULT_API_ERROR, ...body.error };
            return body;
        } catch (error) {
            throw error.statusCode ? error : { message: DEFAULT_API_ERROR };
        }
    };
    const getStatusFromMetaDialog = async (id: string) => {
        try {
            const response = await fetch(`${USER_SERVICE_URL}/api/v1/knowledge/modelStatus?botId=${id}`);
            const body = await response.json();
            if (body.error) {
                return body.error.detail;
            } else {
                return body.status;
            }
        } catch (error) {
            throw error;
        }
    };

    const getFineTuneStatus = async (id: string) => {
        try {
            const response = await fetch(`${USER_SERVICE_URL}/api/v1/fine_tuning/getInUse?botId=${id}`);
            const body = await response.json();
            if (body.error) {
                return body.error.detail;
            } else {
                return body.status;
            }
        } catch (error) {
            throw error;
        }
    };

    const sendFineTune = async (id: string) => {
        const options: RequestInit = {
            method: 'POST',
        };
        try {
            const response = await fetch(`${USER_SERVICE_URL}/api/v1/fine_tuning/send?botId=${id}`, options);
            const body = await response.json();
            if (body.error) {
                return body.error.detail;
            } else {
                return body.status;
            }
        } catch (error) {
            throw error;
        }
    };

    return {
        eraseUser,
        getById,
        getByAgentId,
        quantity,
        getDialogById,
        getUserData,
        getAgentSessions,
        getSessions,
        updateTags,
        getMessagesAnalytics,
        getConversationUsersAnalytics,
        getSessionsAnalytics,
        getPerformanceAnalytics,
        createFineTuning,
        getFineTuningDataMessage,
        createOrUpdateFineTuningData,
        createKnowledge,
        deleteKnowledge,
        getKnowledgeByIds,
        loadKnowledgeForMetaDialog,
        getLeadsCountOfUsers,
        getStatusFromMetaDialog,
        getFineTuneStatus,
        sendFineTune,
    };
};

export default users;

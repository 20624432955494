import React, { useEffect } from "react";
import { useApi } from '../../../../../../functionLib/hooks';
import UI from "./styled";

const LogOut = () => {
  const { customers } = useApi();

  const logOut = async () => {
    customers.logOut();
  };
  useEffect(() => {
    window.analytics.track("Bot Settings Page Visited", {
      Tab: "My account",
      Platform: "Architect"
    });
  }, []);
  return (
    <UI.LogOutContainer>
      <UI.LogOutButton onClick={logOut}>Log out</UI.LogOutButton>
    </UI.LogOutContainer>
  );
};

export default LogOut;

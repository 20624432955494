import React, { } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import logo from './logo.svg';
import './logo.css';

const AppNavBarLogoComponentMain = styled.div`
    height: 80px;
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const AppNavBarLogoImg = styled.img`
    height: 40px;
`;
const AppNavBarLogoComponent = (props) => {
    const { linkTo, imgSrc } = props;

    return (
        <AppNavBarLogoComponentMain>
            <Link to={linkTo}>
                <AppNavBarLogoImg src={imgSrc} />
            </Link>
        </AppNavBarLogoComponentMain>
    );
};
AppNavBarLogoComponent.propTypes = {
    linkTo: PropTypes.string,
    imgSrc: PropTypes.string,
};

AppNavBarLogoComponent.defaultProps = {
    linkTo: '/bots',
    imgSrc: logo,

};
export default AppNavBarLogoComponent;

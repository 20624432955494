import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AgentsListItem, GetAgentsResult } from '../../../../api/models/agents.types';
import { useAppState } from '../../../../engine/globalStateContext';
import getRolesAndUpdateState from '../../../../functionLib/getRolesAndUpdateState';
import { useApi } from '../../../../functionLib/hooks';
import svgLib from '../../../../functionLib/svglib';
import { useNotificationsActions } from '../../../../hooks/notifications/actions';
import { botActions } from '../../../../redux/actions';
import { useAppBot } from '../../../../redux/bot/bot.selectors';
import { useUnsafeCustomer } from '../../../../redux/customer/customer.selectors';
import { useAppDispatch } from '../../../../redux/hooks';
import AppHeaderComponent from '../../../UIComponents/AppHeaderComponent/AppHeaderComponent';
import BotsStatsComponent from '../../../UIComponents/BotsStatsComponent/BotsStatsComponent';
import GeneralTogleComponent from '../../../UIComponents/GeneralTogleComponent/GeneralTogleComponent';
import LoadingComponent from '../../../UIComponents/LoadingComponent/LoadingComponent';
import TableComponent from '../../../UIComponents/TableComponet/TableComponent';
import { isNotNull } from '../../mainOldCanvas/FlowBuilderComponent/utils/guards';
import './BotsComponent.css';
import folder from './img/folder.png';
import { getBotLink } from './utils';

const BotsComponentMain = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const BOTS_LIMIT = 20;

export type BotsProps = { setShowSidebar: (val: boolean) => void };

const BotsComponent: React.FC<BotsProps> = (props) => {
    const api = useApi();
    const { customerRoles, customer } = useUnsafeCustomer();
    const appDispatch = useAppDispatch();
    const [{ sidebar }] = useAppState();
    const [dataLength, setDataLength] = useState(0);
    const [lastScrollTop, setLastScrollTop] = useState(0);
    const [tableLoading, setTableLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [getScrollData, setGetScrollData] = useState(true);
    const [fetchLoading, setFetchLoading] = useState(false);
    const [sortState, setSortState] = useState('');

    const { botList } = useAppBot();

    const [offSet, setOffSet] = useState(BOTS_LIMIT);

    const n11s = useNotificationsActions();

    const getBotsList = () => {
        api.agents
            .get(
                {
                    json: false,
                    skip: 0,
                    limit: BOTS_LIMIT,
                    sort: sortState,
                },
                {
                    _id: true,
                    title: true,
                    messengers: true,
                    status: true,
                    createdAt: true,
                    updatedAt: true,
                    savedAt: true,
                    plan: true,
                }
            )
            .then((agentsData: GetAgentsResult<AgentsListItem>) => {
                setDataLength(agentsData.totalAmount);
                appDispatch(botActions.setBotList(agentsData.agents));
                setTableLoading(false);
                setOffSet(BOTS_LIMIT);
                // setLoading(false);

                /**
                 * TODO:  Here should be called reducer to set botsList = agents
                 */
            })
            .catch((error) => {
                // setLoading(false);
                n11s.error(error.message, 5);
                console.error('Error while taking bots list for the customer: ', error.message);
                setTableLoading(false);
            });
    };

    const onGetLastBots = (e) => {
        const element = e.target;

        if (
            element.scrollTop > (element.scrollHeight - element.clientHeight) / 2 &&
            getScrollData &&
            dataLength > botList.length &&
            element.scrollTop >= lastScrollTop
        ) {
            setFetchLoading(true);
            setGetScrollData(false);

            api.agents
                .get({
                    json: false,
                    skip: offSet,
                    limit: BOTS_LIMIT,
                    sort: sortState,
                })
                .then((agentsData) => {
                    const updatedArray = [...botList, ...agentsData.agents];

                    appDispatch(botActions.setBotList(updatedArray));
                    setOffSet(offSet + BOTS_LIMIT);

                    /**
                     * TODO:  Here should be called reducer to set botsList = agents
                     */
                    setGetScrollData(true);
                    setFetchLoading(false);
                })
                .catch((error) => {
                    n11s.error(error.message, 5);
                    console.error('Error while taking bots list for the customer: ', error.message);
                    setGetScrollData(true);
                    setFetchLoading(false);
                });
        }

        setLastScrollTop(element.scrollTop);
    };

    const getRolesAndBots = async () => {
        await getRolesAndUpdateState(api, appDispatch);
        getBotsList();
    };

    const data = [...botList];

    const onSort = (dataSort) => {
        setSortState(`${dataSort.direction ? '' : '-'}${dataSort.by}`);
    };
    useEffect(() => {
        setTableLoading(true);
        getRolesAndBots();
        (window as any).analytics.track('Bots Page Visited', {
            Platform: 'Architect',
        });
    }, []);

    useEffect(() => {
        if (!customerRoles.length) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [customerRoles]);

    useEffect(() => {
        setTableLoading(true);
        const contentTableStyle = document.querySelector('.ContentTableStyle');
        if (contentTableStyle) {
            contentTableStyle.scrollTop = 0;
        }
        getBotsList();
    }, [sortState]);

    return (
        <BotsComponentMain>
            {loading && (
                <div className="bots_loading">
                    <LoadingComponent />
                </div>
            )}
            <AppHeaderComponent
                setShowSidebar={props.setShowSidebar}
                text={dataLength ? `All bots (${dataLength})` : ''}
                rightButton={
                    <Link to="/bots/newbot/all" className="new_bot_button">
                        <span>New bot</span>
                    </Link>
                }
            />
            <div
                className="bots_main_container"
                style={{
                    flex: '1',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '0px 20px 20px 20px',
                }}
            >
                <div className="bots_stats_container">
                    <BotsStatsComponent
                        name="Users sentiments stats"
                        onClickSettings={() =>
                            sidebar?.show({
                                name: 'Charts settings',
                                content: <div>Stats settings</div>,
                                width: '380px',
                            })
                        }
                    />
                    <BotsStatsComponent
                        name="Total users quantity stats"
                        onClickSettings={() =>
                            sidebar?.show({
                                name: 'Charts settings',
                                content: <div>Stats settings</div>,
                                width: '380px',
                            })
                        }
                    />
                    <BotsStatsComponent
                        name="Conversations activity stats"
                        onClickSettings={() =>
                            sidebar?.show({
                                name: 'Charts settings',
                                content: <div>Stats settings</div>,
                                width: '380px',
                            })
                        }
                    />
                </div>
                {botList.length ? (
                    <GeneralTogleComponent
                        name={`My bots (${dataLength})`}
                        img={folder}
                        isOpened
                        className="group_bot_class"
                    >
                        <div className="bots_group_wrapper">
                            {customerRoles && (
                                <TableComponent
                                    data={data}
                                    columns={[
                                        {
                                            Header: 'Bot name',
                                            field: 'title',
                                            fieldClassName: 'bot_table_name',
                                            className: 'bot_name_flex_3',
                                            sortBy: 'title',
                                            CustomField: (item) => (
                                                <Link
                                                    className="bot_table_link_style"
                                                    to={getBotLink(customerRoles, item.tr._id, customer)}
                                                >
                                                    <div className="bot_table_title_wrapper">
                                                        {item.tr.plan &&
                                                            typeof item.tr.plan === 'string' &&
                                                            item.tr.plan !== 'free' && (
                                                                <span className="bot_table_pro_plan">
                                                                    {item.tr.plan === 'appsumo_unlimited' ||
                                                                    item.tr.plan === 'appsumo'
                                                                        ? 'RESELLER'
                                                                        : item.tr.plan.toUpperCase()}
                                                                </span>
                                                            )}
                                                        {item.tr.title}
                                                    </div>
                                                </Link>
                                            ),
                                        },
                                        {
                                            Header: 'Messengers',
                                            field: 'title',
                                            sortBy: 'messengers',
                                            fieldClassName: 'bot_table_field',
                                            CustomField: (item) => (
                                                <div
                                                    style={{
                                                        width: '100%',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                    }}
                                                >
                                                    <div style={{ marginRight: '2px' }}>
                                                        {item.tr.messengers.facebook
                                                            ? svgLib.messenger('#006CFF', {
                                                                  height: '20px',
                                                                  width: '20px',
                                                              })
                                                            : null}
                                                    </div>
                                                    <div style={{ marginRight: '2px' }}>
                                                        {item.tr.messengers.telegram
                                                            ? svgLib.telegram('#006CFF', {
                                                                  height: '20px',
                                                                  width: '20px',
                                                              })
                                                            : null}
                                                    </div>
                                                    <div>
                                                        {item.tr.messengers.twilio
                                                            ? svgLib.twilio('#006CFF', {
                                                                  height: '20px',
                                                                  width: '20px',
                                                              })
                                                            : null}
                                                    </div>
                                                </div>
                                            ),
                                            className: 'bot_name_flex_2',
                                        },
                                        {
                                            Header: 'Created',
                                            field: 'title',
                                            sortBy: 'createdAt',
                                            fieldClassName: 'bot_table_field',
                                            CustomField: (item) => (
                                                <span>{moment(item.tr.createdAt).format('D MMM YYYY')}</span>
                                            ),
                                            className: 'bot_name_flex_2',
                                        },
                                        (window as any).innerWidth > 414
                                            ? {
                                                  Header: 'Updated',
                                                  field: 'title',
                                                  sortBy: 'savedAt',
                                                  fieldClassName: 'bot_table_field',
                                                  CustomField: (item) => (
                                                      <span>{moment(item.tr.savedAt).format('D MMM YYYY')}</span>
                                                  ),
                                                  className: 'bot_name_flex_2',
                                              }
                                            : null,
                                        (window as any).innerWidth > 414
                                            ? {
                                                  Header: 'Status',
                                                  field: 'status',
                                                  fieldClassName: 'bot_table_field',
                                                  CustomField: (item) =>
                                                      item.tr.status === 'active' ? (
                                                          <span>Active</span>
                                                      ) : (
                                                          <span style={{ color: '#ff6f61' }}>Frozen</span>
                                                      ),
                                                  className: 'bot_name_flex_2',
                                              }
                                            : null,
                                    ].filter(isNotNull)}
                                    getSelectedRow={() => {}}
                                    maxHeight="calc(100vh - 274px)"
                                    minHeight={!tableLoading ? 'auto' : 'calc(100vh - 510px)'}
                                    loading={tableLoading}
                                    onScroll={onGetLastBots}
                                    fetching={fetchLoading}
                                    onSort={(dataSort) => onSort(dataSort)}
                                />
                            )}
                        </div>
                    </GeneralTogleComponent>
                ) : null}
            </div>
        </BotsComponentMain>
    );
};

export default BotsComponent;
